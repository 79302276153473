import {
  Button,
  ButtonBase,
  Dialog,
  Divider,
  IconButton,
  SwipeableDrawer,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Close as CloseIcon, ExpandLess, ExpandMore } from "@material-ui/icons";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import EventTypes from "../../../constants/event-types";
import { PaymentTypes } from "../../../constants/payment-types";
import plans from "../../../constants/plans";
import { SubscriptionTypes } from "../../../constants/subscription-types";
import { navigateToPage } from "../../../helpers/navigation-helper/navigation-helper";
import eventService from "../../../services/event-service";
import BoxDetailsNavigator from "../../box-details-navigator";
import SignupForm from "../signup-form";
import SignupProgressStepper from "../signup-progress-stepper/SignupProgressStepper";
import useStyles from "./styles";
import { ExpandLess as ExpandLessIcon } from "@material-ui/icons";

function SignupDialog() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] =
    useState(false);
  const [open, setOpen] = useState(false);
  const [selectedSubscriptionType, setSelectedSubscriptionType] = useState(
    SubscriptionTypes.Complete
  );
  const [selectedPaymentType, setSelectedPaymentType] = useState(
    PaymentTypes.YEARLY
  );
  const [selectedMonth, setSelectedMonth] = useState(1);
  const [bottomMenuOpen, setBottomMenuOpen] = useState(false);

  useEffect(() => {
    eventService.addListener(
      EventTypes.TERMS_AND_CONDITIONS_CHANGED,
      (accepted: boolean) => {
        setTermsAndConditionsAccepted(accepted);
      }
    );

    eventService.addListener(
      EventTypes.OPEN_SIGNUP_FORM,
      (
        selectedSubscriptionType: SubscriptionTypes,
        selectedPaymentType: PaymentTypes
      ) => {
        setSelectedSubscriptionType(selectedSubscriptionType);
        setSelectedPaymentType(selectedPaymentType);
        setOpen(true);
      }
    );

    eventService.addListener(
      EventTypes.SELECTED_MONTH_CHANGED,
      (month: number) => {
        setSelectedMonth(month);
      }
    );

    eventService.addListener(
      EventTypes.SELECTED_SUBSCRIPTION_TYPE_CHANGED,
      (subscriptionType: SubscriptionTypes) => {
        setSelectedSubscriptionType(subscriptionType);
      }
    );

    return () => {
      eventService.removeListener(
        EventTypes.TERMS_AND_CONDITIONS_CHANGED,
        (_accepted: boolean) => setTermsAndConditionsAccepted(false)
      );

      eventService.removeListener(
        EventTypes.OPEN_SIGNUP_FORM,
        (selectedSubscriptionType: SubscriptionTypes) => {
          setOpen(false);
        }
      );

      eventService.removeListener(EventTypes.SELECTED_MONTH_CHANGED, () =>
        setSelectedMonth(0)
      );

      eventService.removeListener(
        EventTypes.SELECTED_SUBSCRIPTION_TYPE_CHANGED,
        () => setSelectedSubscriptionType(SubscriptionTypes.Complete)
      );
    };
  }, []);

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setTermsAndConditionsAccepted(false);
      }, 200);
    }
  }, [open]);

  function closeDialog(): void {
    setOpen(false);
  }

  function subscriptionCreated(): void {
    navigateToPage("/assinatura-recebida");
    setOpen(false);
  }

  return (
    <Dialog open={open} onClose={closeDialog} fullScreen fullWidth>
      <div className={classes.dialogContent}>
        {!isMobile ? (
          <div
            className={classNames(
              classes.leftContainer,
              !termsAndConditionsAccepted &&
                classes.leftContainerWithBackgroundImage
            )}
          >
            {termsAndConditionsAccepted ? (
              <BoxDetailsNavigator
                selectedSubscriptionType={selectedSubscriptionType}
                selectedMonth={selectedMonth}
              />
            ) : null}
          </div>
        ) : null}
        <div className={classes.rightArea}>
          {!isMobile || !termsAndConditionsAccepted ? (
            <div
              className={classNames(
                classes.topArea,
                !termsAndConditionsAccepted && isMobile
                  ? classes.termsAndConditionsTopArea
                  : null
              )}
            >
              <IconButton
                size="small"
                onClick={closeDialog}
                className={classNames(
                  isMobile ? classes.mobileCloseButton : null
                )}
              >
                <CloseIcon />
              </IconButton>
            </div>
          ) : null}
          {termsAndConditionsAccepted ? (
            <div className={classes.stepperArea}>
              <SignupProgressStepper selectedMonth={selectedMonth} />
            </div>
          ) : null}
          {termsAndConditionsAccepted ? (
            <Divider className={classes.divider} />
          ) : null}
          <div className={classes.formArea}>
            <SignupForm
              selectedPaymentType={selectedPaymentType}
              selectedSubscriptionType={selectedSubscriptionType}
              subscriptionCreated={subscriptionCreated}
            />
            {isMobile && termsAndConditionsAccepted ? (
              <>
                <ButtonBase
                  onClick={() => setBottomMenuOpen(!bottomMenuOpen)}
                  className={classes.bottomDrawerTrigger}
                >
                  <ExpandLessIcon className={classes.expandLessIcon} />
                </ButtonBase>
                <SwipeableDrawer
                  anchor="bottom"
                  open={bottomMenuOpen}
                  onClose={() => setBottomMenuOpen(false)}
                  onOpen={() => setBottomMenuOpen(true)}
                  swipeAreaWidth={0}
                  ModalProps={{
                    keepMounted: true,
                  }}
                  PaperProps={{
                    style: {
                      height: "100vh",
                    },
                  }}
                >
                  <div className={classes.bottomDrawerBoxDetailsComponent}>
                    <BoxDetailsNavigator
                      selectedSubscriptionType={selectedSubscriptionType}
                      selectedMonth={selectedMonth}
                      onCloseDrawer={() => setBottomMenuOpen(false)}
                    />
                  </div>
                </SwipeableDrawer>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default SignupDialog;
