import {
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import classNames from "classnames";
import { Form, Formik } from "formik";
import React, { useRef, useState } from "react";
import InputMask from "react-input-mask";
import * as Yup from "yup";
import useStyles from "./styles";
import cep from "cep-promise";
import { areAllValuesFilled } from "../../../../helpers/form-helper/form-helper";

interface IAddressStepProps {
  onAdvanceStep: Function;
  onReturnStep: Function;
  subscription: any;
  isLoading?: boolean;
}

interface ICEPAddressDetails {
  street: string;
  city: string;
  state: string;
  neighborhood: string;
}

function MaskedZipCodeInput(props) {
  const classes = useStyles();

  return (
    <InputMask
      mask="99999-999"
      value={props.value}
      onChange={props.onChange}
      maskPlaceholder=""
      onBlur={() => {
        props.getAddressDetails(props.value);
      }}
    >
      <TextField
        fullWidth
        autoFocus
        variant="outlined"
        placeholder="CEP"
        className={classes.textInput}
        error={Boolean(props.errors.zipCode)}
        helperText={props.errors.zipCode}
      />
    </InputMask>
  );
}

function AddressStep(props: IAddressStepProps) {
  const classes = useStyles();
  const formRef = useRef();

  const [cepWithDetails, setCepWithDetails] = useState(false);

  const addressStepInitialValues = {
    zipCode: props.subscription?.address?.zipCode || "",
    state: props.subscription?.address?.state || "",
    city: props.subscription?.address?.city || "",
    neighborhood: props.subscription?.address?.neighborhood || "",
    street: props.subscription?.address?.street || "",
    number: props.subscription?.address?.number || "",
    complement: props.subscription?.address?.complement || "",
  };

  const addressStepFormSchema = Yup.object().shape({
    zipCode: Yup.string().required("Preencha o CEP."),
    state: Yup.string().required("Preencha o estado."),
    city: Yup.string().required("Preencha a cidade."),
    neighborhood: Yup.string().required("Preencha o bairro."),
    street: Yup.string().required("Preencha a Rua."),
    number: Yup.string().required("Preencha o numero."),
    complement: Yup.string(),
  });

  async function getAddressDetails(zipCode: string): Promise<void> {
    try {
      const data: ICEPAddressDetails = await cep(zipCode);
      if (data.street) {
        setCepWithDetails(true);
      }

      if (formRef.current) {
        Object.keys(data).forEach((key) => {
          if (Object.keys(addressStepInitialValues).includes(key)) {
            formRef.current.setFieldValue(key, data[key]);
            formRef.current.validateField(key);
          }
        });
        formRef.current.validateField("zipCode");
      }
    } catch (error) {
      // @ts-expect-error
      formRef.current.setFieldError("zipCode", "Preencha um CEP valido.");
      console.error(error);
    }
  }

  return (
    <div className={classes.addressStep}>
      <Typography className={classes.titleText}>
        Preencha os campos abaixo.
      </Typography>
      <div className={classes.formContainer}>
        <Formik
          initialValues={addressStepInitialValues}
          validationSchema={addressStepFormSchema}
          onSubmit={(values) => props.onAdvanceStep({ address: values })}
          validateOnChange={false}
          validateOnBlur={true}
          isInitialValid={false}
          innerRef={formRef}
        >
          {({
            submitForm,
            errors,
            values,
            setFieldValue,
            validateField,
            isValid,
            dirty,
          }) => {
            if (!dirty && areAllValuesFilled(values, ["complement"])) {
              isValid = true;
            }
            function handleInputChange(field: string, value: string): void {
              setFieldValue(field, value);
            }

            return (
              <Form className={classes.form}>
                <div className={classes.formArea}>
                  <MaskedZipCodeInput
                    onChange={(event) =>
                      handleInputChange("zipCode", event.target.value)
                    }
                    value={values.zipCode}
                    errors={errors}
                    validateField={validateField}
                    getAddressDetails={getAddressDetails}
                  />
                  <div className={classes.row}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Rua"
                      className={classes.textInput}
                      error={Boolean(errors.street)}
                      value={values.street}
                      helperText={errors.street}
                      disabled={cepWithDetails}
                      onChange={(event) =>
                        handleInputChange("street", event.target.value)
                      }
                      onBlur={() => validateField("street")}
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Bairro"
                      className={classes.textInput}
                      error={Boolean(errors.neighborhood)}
                      value={values.neighborhood}
                      helperText={errors.neighborhood}
                      disabled={cepWithDetails}
                      onChange={(event) =>
                        handleInputChange("neighborhood", event.target.value)
                      }
                      onBlur={() => validateField("neighborhood")}
                    />
                  </div>
                  <div className={classes.row}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Cidade - Estado"
                      className={classes.textInput}
                      error={Boolean(errors.city)}
                      value={
                        values.city ? `${values.city} - ${values.state}` : ""
                      }
                      helperText={errors.city}
                      disabled
                      onChange={(event) =>
                        handleInputChange("city", event.target.value)
                      }
                    />
                  </div>
                  <div className={classes.row}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Numero"
                      className={classes.textInput}
                      error={Boolean(errors.number)}
                      value={values.number}
                      helperText={errors.number}
                      onChange={(event) =>
                        handleInputChange("number", event.target.value)
                      }
                      onBlur={() => validateField("number")}
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Complemento"
                      className={classes.textInput}
                      error={Boolean(errors.complement)}
                      value={values.complement}
                      helperText={errors.complement}
                      onChange={(event) =>
                        handleInputChange("complement", event.target.value)
                      }
                      onBlur={() => validateField("complement")}
                    />
                  </div>
                </div>
                <div className={classes.submitButtonArea}>
                  <Button
                    variant="contained"
                    disabled={!isValid || props.isLoading}
                    onClick={submitForm}
                    className={classNames(classes.button, classes.submitButton)}
                  >
                    Continuar
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => props.onReturnStep()}
                    className={classNames(classes.returnButton, classes.button)}
                  >
                    Voltar
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default AddressStep;
