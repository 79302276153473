import { Button, TextField, Typography } from "@material-ui/core";
import classNames from "classnames";
import { Form, Formik } from "formik";
import React from "react";
import InputMask from "react-input-mask";
import * as Yup from "yup";
import { areAllValuesFilled } from "../../../../helpers/form-helper/form-helper";
import yupValidations from "../../../../helpers/yup-validations";
import useStyles from "./styles";

interface IPersonalInfoStepProps {
  onAdvanceStep: Function;
  onReturnStep: Function;
  subscription: any;
}

type MaskedFieldProps = {
  errors: { [key: string]: any };
  onChange: (event: { target: { value: string } }) => void;
  validateField: (field: string) => void;
  value: string;
};

function MaskedCPFInput(props: MaskedFieldProps) {
  const classes = useStyles();

  return (
    <InputMask
      mask="999.999.999-99"
      value={props.value}
      onChange={props.onChange}
      maskPlaceholder=""
      onBlur={() => props.validateField("cpf")}
    >
      <TextField
        fullWidth
        variant="outlined"
        placeholder="CPF"
        className={classes.textInput}
        error={Boolean(props.errors.cpf)}
        helperText={props.errors.cpf}
      />
    </InputMask>
  );
}

function MaskedPhoneInput(props: MaskedFieldProps) {
  const classes = useStyles();

  return (
    <InputMask
      mask="99 99999-9999"
      value={props.value}
      onChange={props.onChange}
      maskPlaceholder=""
      onBlur={() => props.validateField("phoneNumber")}
    >
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Celular"
        className={classes.textInput}
        error={Boolean(props.errors.phoneNumber)}
        helperText={props.errors.phoneNumber}
        FormHelperTextProps={{
          style: {
            fontSize: 24,
          },
        }}
      />
    </InputMask>
  );
}

function PersonalInfoStep(props: IPersonalInfoStepProps) {
  const classes = useStyles();

  const personalInfoStepFormInitialValues = {
    name: props.subscription.name || "",
    cpf: props.subscription.cpf || "",
    email: props.subscription.email || "",
    phoneNumber: props.subscription.phoneNumber || "",
  };

  const personalInfoStepFormSchema = Yup.object().shape({
    name: Yup.string().required("Preencha seu nome."),
    cpf: yupValidations.cpf,
    email: yupValidations.email,
    phoneNumber: Yup.string().required("Preencha seu celular."),
  });

  return (
    <div className={classes.personalInfoStep}>
      <div>
        <Typography className={classes.stepTitle}>
          Preencha os campos abaixo.
        </Typography>
        <div>
          <Typography variant="h5" className={classes.stepInfoText}>
            Não se preocupe, suas informações estão protegidas com a gente e não
            compartilharemos com ninguém!
          </Typography>
        </div>
      </div>
      <div className={classes.formContainer}>
        <Formik
          initialValues={personalInfoStepFormInitialValues}
          validationSchema={personalInfoStepFormSchema}
          onSubmit={(values) => props.onAdvanceStep(values)}
          validateOnChange={false}
          validateOnBlur={true}
          isInitialValid={false}
        >
          {({
            submitForm,
            errors,
            values,
            setFieldValue,
            validateField,
            isValid,
            dirty,
          }) => {
            if (!dirty && areAllValuesFilled(values)) {
              isValid = true;
            }
            function handleInputChange(field: string, value: string): void {
              setFieldValue(field, value);
            }

            return (
              <Form className={classes.form}>
                <div className={classes.formArea}>
                  <div className={classes.column}>
                    <TextField
                      fullWidth
                      autoFocus
                      variant="outlined"
                      placeholder="Nome completo"
                      className={classes.textInput}
                      error={Boolean(errors.name)}
                      helperText={errors.name}
                      onChange={(event) =>
                        handleInputChange("name", event.target.value)
                      }
                      value={values.name}
                      onBlur={() => validateField("name")}
                    />
                    <MaskedCPFInput
                      onChange={(event) =>
                        handleInputChange("cpf", event.target.value)
                      }
                      value={values.cpf}
                      errors={errors}
                      validateField={validateField}
                    />
                  </div>
                  <div className={classes.column}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="E-mail"
                      className={classes.textInput}
                      error={Boolean(errors.email)}
                      helperText={errors.email}
                      value={values.email}
                      onChange={(event) =>
                        handleInputChange("email", event.target.value)
                      }
                      onBlur={() => validateField("email")}
                    />
                    <MaskedPhoneInput
                      onChange={(event) =>
                        handleInputChange("phoneNumber", event.target.value)
                      }
                      value={values.phoneNumber}
                      errors={errors}
                      validateField={validateField}
                    />
                  </div>
                </div>
                <div className={classes.submitButtonArea}>
                  <Button
                    variant="contained"
                    disabled={!isValid || !areAllValuesFilled(values)}
                    onClick={submitForm}
                    className={classNames(classes.submitButton, classes.button)}
                  >
                    Continuar
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => props.onReturnStep()}
                    className={classNames(classes.returnButton, classes.button)}
                  >
                    Voltar
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default PersonalInfoStep;
