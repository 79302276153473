import {
  Button,
  ClickAwayListener,
  Fade,
  MenuItem,
  Popper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  KeyboardArrowDown as ArrowDownIcon,
  KeyboardArrowUp as ArrowUpIcon,
} from "@material-ui/icons";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";

interface DefaultSelectProps {
  options: any[];
  selectedOption: any;
  onSelectOption: Function;
  labelComplement?: string;
}

function DefaultSelect({
  options,
  selectedOption,
  onSelectOption,
  labelComplement = "",
}: DefaultSelectProps) {
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [anchorEl, setAnchorEl] = useState(null);
  const [buttonSize, setButtonSize] = useState(0);

  useEffect(() => {
    getButtonSize();

    return () => {
      setAnchorEl(null);
      setButtonSize(0);
    };
  }, []);

  useEffect(() => {
    getButtonSize();
  }, [isMobile]);

  function openMenu(event: any): void {
    if (Boolean(anchorEl)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  }

  function getButtonSize(): void {
    const element = document.getElementById("default-select");
    setButtonSize(element.clientWidth as number);
  }

  return (
    <div className={classes.defaultSelectButtonContainer}>
      <Button
        id="default-select"
        variant="contained"
        className={classes.defaultSelectButton}
        endIcon={Boolean(anchorEl) ? <ArrowUpIcon /> : <ArrowDownIcon />}
        onClick={(event) => openMenu(event)}
        style={{
          borderBottomLeftRadius: Boolean(anchorEl) ? 0 : 30,
          borderBottomRightRadius: Boolean(anchorEl) ? 0 : 30,
        }}
      >
        {`${labelComplement} ${selectedOption}`}
      </Button>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        placement="bottom-end"
        className={classes.popper}
        style={{
          width: buttonSize,
        }}
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: false,
            boundariesElement: "scrollParent",
          },
        }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            <Fade {...TransitionProps}>
              <div className={classes.defaultSelectMenuContainer}>
                {options.map((option, index) => (
                  <MenuItem
                    key={index}
                    className={classes.defaultSelectOptionItem}
                    onClick={() => {
                      onSelectOption(option);
                      setTimeout(() => {
                        setAnchorEl(null);
                      }, 150);
                    }}
                  >
                    <Typography
                      className={classNames(
                        classes.defaultSelectOptionText,
                        option === selectedOption
                          ? classes.selectedOptionText
                          : null
                      )}
                      variant="h5"
                    >
                      {option}
                    </Typography>
                  </MenuItem>
                ))}
              </div>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </div>
  );
}

export default DefaultSelect;
