import { makeStyles } from "@material-ui/core";
import centerAreaBackground from "../../assets/images/center-area-backround.png";
import blueBackground from "../../assets/images/blue-background.png";
import contentBackground from "../../assets/images/content-background.png";
import contentBackgroundWithoutIcons from "../../assets/images/content-background-without-icons.png";

const useStyles = makeStyles((theme) => ({
  plansArea: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  lineIcons: {
    position: "absolute",
    width: "100%",
    marginTop: "-10vh",
  },
  plansTopArea: {
    backgroundImage: `url(${centerAreaBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    paddingTop: "25vh",
    paddingBottom: "10vh",
    display: "flex",
    flexDirection: "column",
    marginTop: "-3vh",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-40vh",
      paddingBottom: "15vh",
    },
  },
  plansTitleArea: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  plansTitleText: {
    fontFamily: "Frenchpress",
    color: theme.palette.secondary.main,
    letterSpacing: "4%",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.h3.fontSize,
    },
  },
  plansContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 156,
  },
  boxesArea: {
    width: "100vw",
    marginTop: "-10vh",
    backgroundImage: `url(${blueBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    paddingTop: "20vh",
    paddingBottom: "35vh",
    [theme.breakpoints.down("sm")]: {},
  },

  titleArea: {
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  boxesTitleText: {
    fontFamily: "Frenchpress",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.h3.fontSize,
      textAlign: "center",
    },
  },
  boxesDescriptionText: {
    fontFamily: "Dosis, Roboto",
    fontWeight: 700,
    color: theme.palette.text.primary,
    marginTop: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.body1.fontSize,
      textAlign: "center",
    },
  },
  plansDisplayer: {
    marginTop: 56,
  },
  planQuoteArea: {
    marginTop: "-25vh",
    width: "100%",
    backgroundImage: `url(${contentBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    paddingTop: "35vh",
    paddingBottom: "20vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "-10vh",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "15vh",
      paddingBottom: "20vh",
      marginBottom: "-15vh",
      backgroundImage: `url(${contentBackgroundWithoutIcons})`,
    },
  },
  planQuoteText: {
    fontFamily: "Frenchpress, Roboto",
    fontWeight: 400,
    letterSpacing: "4%",
    color: theme.palette.secondary.main,
    textAlign: "center",
    width: "80%",
    marginTop: 16,
    marginBottom: 8,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.h3.fontSize,
      textAlign: "center",
    },
  },
  planQuoteSubtitle: {
    color: theme.palette.text.secondary,
    fontFamily: "Dosis, Roboto",
    fontWeight: 700,
    marginBottom: 42,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.h5.fontSize,
    },
  },
  openQuotesIcon: {
    [theme.breakpoints.down("sm")]: {
      width: 24,
      height: 24,
    },
  },
  closeQuotesIcon: {
    [theme.breakpoints.down("sm")]: {
      width: 24,
      height: 24,
    },
  },
}));

export default useStyles;
