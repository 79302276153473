import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  personalInfoStep: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      overflow: "auto",
      paddingBottom: 86,
    },
  },
  stepInfoText: {
    marginTop: 10,
    fontWeight: 500,
    fontFamily: "Roboto",
    color: "#7C8999",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  stepTitle: {
    fontWeight: 700,
    fontSize: 36,
    color: "rgba(87, 190, 191, 1)",
    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
    },
  },
  form: {
    height: "100%",
  },
  formContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: 16,
  },
  formArea: {
    flex: 1,
    height: "100%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  column: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginLeft: 5,
    marginRight: 5,
    [theme.breakpoints.down("sm")]: {
      flex: 0,
    },
  },
  textInput: {
    marginBottom: 10,
    minHeight: 80,
    "&  .MuiFormHelperText-root.Mui-error": {
      fontSize: 20,
      marginTop: 0,
    },
  },
  submitButtonArea: {
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      marginTop: "-10vh",
      gap: 10,
    },
  },
  submitButton: {
    backgroundColor: "#57BEBF",
    color: "#FFF !important",
    "&:hover": {
      backgroundColor: "#57BEBF",
    },
  },
  returnButton: {
    backgroundColor: "#FFF !important",
    color: "#57BEBF",
    marginRight: 10,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  howYouFoundUsText: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.h6.fontSize,
      textAlign: "center",
    },
  },
  button: {
    width: 186,
    height: 50,
    fontSize: 24,
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

export default useStyles;
