import { Button, Divider, Hidden, Typography } from "@material-ui/core";
import {
  ArrowForward as RightIcon,
  CheckCircle as CheckIcon,
} from "@material-ui/icons";
import classNames from "classnames";
import React from "react";
import EventTypes from "../../../constants/event-types";
import {
  PaymentTypeLabels,
  PaymentTypes,
} from "../../../constants/payment-types";
import { subscriptionAdvantages } from "../../../constants/subscription-advantages";
import {
  SubscriptionTypeLabels,
  SubscriptionTypes,
} from "../../../constants/subscription-types";
import eventService from "../../../services/event-service";
import DefaultSelect from "../../default-select/DefaultSelect";
import useStyles from "./styles";
import { SINGLE_SHOP_WEBSITE_URL } from "../../../constants/single-shop-website-url";
import { navigateToLink } from "../../../helpers/navigation-helper/navigation-helper";

interface PlanAdvantagesBannerProps {
  selectedSubscriptionType: SubscriptionTypes;
  paymentType: PaymentTypes;
  onChangeSelectedSubscriptionType: Function;
  recommended?: boolean;
}

function PlanAdvantagesBanner(props: PlanAdvantagesBannerProps) {
  const classes = useStyles();

  function onSelectSubcriptionType(subscriptionTypeLabel: string): void {
    const subscriptionType = Object.keys(SubscriptionTypeLabels).find(
      (label) => SubscriptionTypeLabels[label] === subscriptionTypeLabel
    );
    props.onChangeSelectedSubscriptionType(subscriptionType);
  }

  function handleSubscribe(
    selectedSubscriptionType: SubscriptionTypes,
    selectedPaymentType: PaymentTypes
  ): void {
    if (selectedPaymentType === PaymentTypes.RECURRENCE) {
      eventService.emitEvent(EventTypes.OPEN_SIGNUP_FORM, [
        selectedSubscriptionType,
        selectedPaymentType,
      ]);
    } else {
      navigateToLink(SINGLE_SHOP_WEBSITE_URL);
    }
  }

  function formatBannerLabel(): string {
    switch (props.paymentType) {
      case PaymentTypes.RECURRENCE:
        return "Mensalidade a partir de:";
      case PaymentTypes.SEMIANNUAL: {
        if (props.selectedSubscriptionType === SubscriptionTypes.Basic) {
          return "A partir de 8x de:";
        }
        return "A partir de 11x de:";
      }
      case PaymentTypes.YEARLY:
        return "A partir de 12x de:";
    }
  }

  return (
    <div
      className={classNames(
        classes.planAdvantagesBanner,
        props.recommended ? classes.recommendedIndicator : null
      )}
    >
      <Typography
        className={classNames(
          classes.titleText,
          props.recommended ? classes.recommendedBannerTextColor : null
        )}
      >
        {PaymentTypeLabels[props.paymentType]}
      </Typography>
      <Hidden mdUp>
        <div className={classes.subscriptionTypeSelectorArea}>
          <DefaultSelect
            options={Object.values(SubscriptionTypeLabels)}
            selectedOption={
              SubscriptionTypeLabels[props.selectedSubscriptionType]
            }
            onSelectOption={(selectedOption: string) =>
              onSelectSubcriptionType(selectedOption)
            }
          />
        </div>
      </Hidden>
      <div className={classNames(classes.advantagesArea)}>
        {subscriptionAdvantages[props.selectedSubscriptionType][
          props.paymentType
        ].advantages.map((advantage, index) => (
          <div className={classes.advantageContainer} key={index}>
            <CheckIcon
              className={classNames(
                classes.checkIcon,
                props.recommended ? classes.recommendedBannerTextColor : null
              )}
            />
            <Typography
              className={classNames(
                classes.advantageText,
                props.recommended ? classes.recommendedBannerTextColor : null
              )}
            >
              {advantage};
            </Typography>
          </div>
        ))}
      </div>
      <Divider />
      <div className={classes.priceContainer}>
        <Typography
          className={classNames(
            classes.priceTitleText,
            props.recommended ? classes.recommendedBannerTextColor : null
          )}
        >
          {formatBannerLabel()}
        </Typography>
        <Typography
          className={classNames(
            classes.priceText,
            props.recommended ? classes.recommendedBannerTextColor : null
          )}
        >
          R${" "}
          <span
            className={classNames(
              classes.priceValueText,
              props.recommended ? classes.recommendedBannerTextColor : null
            )}
          >
            {subscriptionAdvantages[props.selectedSubscriptionType][
              props.paymentType
            ].minValue
              .toFixed(2)
              .toString()}
          </span>
          /mês
        </Typography>
        <Button
          variant="contained"
          size="large"
          className={classNames(
            classes.subscribeButton,
            props.recommended ? classes.recommendedBannerSubscribeButton : null
          )}
          endIcon={<RightIcon />}
          onClick={() =>
            handleSubscribe(props.selectedSubscriptionType, props.paymentType)
          }
        >
          Assine já
        </Button>
      </div>
    </div>
  );
}

export default PlanAdvantagesBanner;
