import { SubscriptionTypes } from "./subscription-types";
import { PaymentTypes } from "./payment-types";

export interface SubscriptionAdvantages {
  [subscriptionType: number]: {
    [paymentType: number]: {
      advantages: string[];
      minValue: number;
    };
  };
}

export const subscriptionAdvantages: SubscriptionAdvantages = {
  [SubscriptionTypes.Basic]: {
    [PaymentTypes.RECURRENCE]: {
      advantages: ["Solicitação de cancelamento com no mínimo 30 dias"],
      minValue: 205.16,
    },
    [PaymentTypes.YEARLY]: {
      advantages: [
        "Você escolhe a data de envio das boxes",
        "Desconto na mensalidade",
      ],
      minValue: 177.59,
    },
    [PaymentTypes.SEMIANNUAL]: {
      advantages: [
        "Você escolhe a data de envio das boxes",
        "Desconto na mensalidade",
      ],
      minValue: 106.90,
    },
  },
  [SubscriptionTypes.Complete]: {
    [PaymentTypes.RECURRENCE]: {
      advantages: [
        "Brinquedos exclusivos",
        "Solicitação de cancelamento com no mínimo 30 dias",
      ],
      minValue: 274.4,
    },
    [PaymentTypes.YEARLY]: {
      advantages: [
        "Brinquedos exclusivos",
        "Você escolhe a data de envio das boxes",
        "Desconto na mensalidade",
      ],
      minValue: 249.17,
    },
    [PaymentTypes.SEMIANNUAL]: {
      advantages: [
        "Brinquedos exclusivos",
        "Você escolhe a data de envio das boxes",
        "Desconto na mensalidade",
      ],
      minValue: 104.69,
    },
  },
};
