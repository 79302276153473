import {
  Button,
  CircularProgress,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useState } from "react";
import useStyles from "./styles";
import classNames from "classnames";

interface CompleteSubscriptionStepProps {
  onAdvanceStep: Function;
  onReturnStep: Function;
  subscription: any;
  isLoading: boolean;
}

function CompleteSubscriptionStep(props: CompleteSubscriptionStepProps) {
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [howFoundUs, setHowFoundUs] = useState("");

  return (
    <div className={classes.completeSubscriptionStep}>
      <Typography className={classes.titleText}>
        Como você descobriu a gente?
      </Typography>
      <Typography className={classes.subtitleText}>
        Você pode continuar sem responder!
      </Typography>
      <div className={classes.formArea}>
        <TextField
          multiline
          minRows={12}
          maxRows={12}
          fullWidth={isMobile}
          variant="outlined"
          placeholder="Descreva aqui..."
          className={classes.textArea}
          value={howFoundUs}
          onChange={(event) => setHowFoundUs(event.target.value)}
        />
      </div>
      <div className={classes.buttonsArea}>
        <Button
          variant="contained"
          className={classNames(classes.button, classes.submitButton)}
          disabled={props.isLoading}
          onClick={() =>
            props.onAdvanceStep({
              howFoundUs,
            })
          }
        >
          {!isMobile ? "Prosseguir para pagamento" : "Pagamento"}
          {props.isLoading ? (
            <CircularProgress size={20} className={classes.progressIndicator} />
          ) : null}
        </Button>
        <Button
          variant="contained"
          className={classNames(classes.button, classes.returnButton)}
          disabled={props.isLoading}
          onClick={() => props.onReturnStep()}
        >
          Voltar
        </Button>
      </div>
    </div>
  );
}

export default CompleteSubscriptionStep;
