import { makeStyles } from "@material-ui/core";
import signupDialogImage from "../../../assets/images/signup-dialog-image.png";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    flex: 1,
    display: "flex",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      maxHeight: "100dvh",
      overflow: "hidden",
    },
  },
  leftContainer: {
    flex: 0.5,
    display: "flex",
  },
  leftContainerWithBackgroundImage: {
    backgroundImage: `url(${signupDialogImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  rightArea: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: 32,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1000,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  topArea: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  termsAndConditionsTopArea: {
    backgroundColor: "rgba(87, 190, 191, 1)",
    padding: 20,
  },
  stepperArea: {
    width: "100%",
    display: "flex",
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.text.secondary,
    marginTop: 40,
    marginBottom: 40,
    marginRight: 48,
    marginLeft: 48,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  formArea: {
    flex: 1,
    paddingLeft: 48,
    paddingRight: 48,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      overflowY: "auto",
    },
  },
  mobileCloseButton: {
    backgroundColor: "#FFF !important",
    color: "rgba(124, 137, 153, 1)",
  },
  bottomDrawerTrigger: {
    width: "100%",
    height: 56,
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    backgroundColor: "#F2AF2F",
    position: "fixed",
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 16,
  },
  expandLessIcon: {
    color: "#FFF",
  },
  bottomDrawerBoxDetailsComponent: {
    height: "100%",
  },
}));

export default useStyles;
